import React, { Component } from "react";
import Header from "../components/header/header";
import ProfileDetails from "../components/profileDetails/profileDetails";

class Profile extends Component {
  render() {
    return (
      <div>
        <Header
          authenticated={this.props.authenticated}
          selected={"Activities"}
        />
        <ProfileDetails
          history={this.props.history}
          pathname={this.props.pathname}
        />
      </div>
    );
  }
}

export default Profile;
