import React, { Component } from "react";
import firebase from "../../firebase.js";

import styles from "./profileDetails.module.sass";
import List from "../list/list.js";

class ProfileDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: null,
      isUserProfile: null,
      profileUserId: null,
      following: null,
      followNumber: null
    };
  }
  getProfileUserid = () => {
    const pathname = this.props.history.location.pathname;
    const username = pathname.slice(10, pathname.length);
    const usernameQuery = firebase.database().ref(`usernames/${username}`);
    usernameQuery.once("value", snapshot => {
      const userDetails = snapshot.val();
      this.setState({
        username,
        profileUserId: userDetails.userId,
        isUserProfile: null
      });
    });
  };

  checkIfIsUserProfile = () => {
    const userId = firebase.auth().currentUser.uid;
    if (userId === this.state.profileUserId) {
      this.setState({
        isUserProfile: true
      });
    } else {
      this.setState({
        isUserProfile: false
      });
    }
  };

  checkFollowing = () => {
    console.log("check");
    const userId = firebase.auth().currentUser.uid;
    const followingQuery = firebase.database().ref(`users/${userId}/following`);
    let followingList = [];
    followingQuery.once("value", snapshot => {
      snapshot.forEach(childSnapshot => {
        const followingId = childSnapshot.val();
        console.log(followingId);
        let followingList = [];
        console.log(followingList);
        followingList.push(followingId);
      });
      if (followingList.includes(this.state.profileUserId)) {
        this.setState({
          following: true
        });
      } else {
        this.setState({
          following: false
        });
      }
      const followNumber = followingList.length;
      this.setState({
        followNumber
      });
    });
  };

  handleFollowClick = () => {
    const userId = firebase.auth().currentUser.uid;
    if (!this.state.following) {
      firebase
        .database()
        .ref(`/users/${userId}/following`)
        .child(this.state.profileUserId)
        .set(this.state.profileUserId);
      console.log("added");
      this.setState({
        following: true
      });
    } else if (this.state.following) {
      firebase
        .database()
        .ref(`/users/${userId}/following/${this.state.profileUserId}`)
        .remove();
      console.log("removed");
      this.setState({
        following: false
      });
    }
  };
  componentDidMount() {
    this.getProfileUserid();
  }

  componentDidUpdate() {
    const pathname = this.props.pathname;
    const profilePathname = pathname.slice(10, pathname.length);
    if (profilePathname !== this.state.username) {
      this.getProfileUserid();
    }
    if (this.state.profileUserId && this.state.isUserProfile === null) {
      this.checkIfIsUserProfile();
    }
    if (this.state.isUserProfile !== null && this.state.following === null) {
      this.checkFollowing();
    }
  }

  render() {
    return (
      <div>
        <div className={styles.profileTop}>
          <div className={styles.profileImage}>
            {/* <img className={styles.profileImage} src={} alt="Profile" /> */}
          </div>
          <div>
            <div className={styles.username}>{this.state.username}</div>
            <div>
              {this.state.isUserProfile ? (
                <button className={styles.button}>Edit</button>
              ) : !this.state.following ? (
                <button
                  onClick={() => this.handleFollowClick()}
                  className={styles.button}
                >
                  Follow
                </button>
              ) : (
                <button
                  onClick={() => this.handleFollowClick()}
                  className={styles.buttonFollowing}
                >
                  Following
                </button>
              )}
            </div>
          </div>
        </div>
        <div className={styles.profileStats}>
          <div>
            <div className={styles.number}>16</div>
            <div className={styles.label}>Activities</div>
          </div>
          <div>
            <div className={styles.number}>{this.state.followNumber}</div>
            <div className={styles.label}>Followers</div>
          </div>
          <div>
            <div className={styles.number}>3</div>
            <div className={styles.label}>Following</div>
          </div>
        </div>
        <List
          history={this.props.history}
          profileUserId={this.state.profileUserId}
        />
      </div>
    );
  }
}

export default ProfileDetails;
