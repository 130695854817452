import React, { Component } from "react";
import firebase from "../../firebase.js";
import Activities from "../activities/activities";
import ShowMore from "../showMore/showMore";

import styles from "./featured.module.sass";

class Featured extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activities: [],
      loaded: false,
      limit: 6
    };
  }

  increaseLimit = () => {
    let newLimit = this.state.limit + 6;
    this.setState({
      limit: newLimit
    });
  };

  getActivities() {
    const query = firebase
      .database()
      .ref("activities")
      .orderByChild("featured")
      .equalTo(true)
      .limitToLast(this.state.limit * 2 + 1);
    //ordering function
    query.once("value", snapshot => {
      const response = [];
      snapshot.forEach(childSnapshot => {
        const item = childSnapshot.val();
        item.key = childSnapshot.key;
        response.push(item);
      });
      const activities = response.sort(
        (a, b) => parseFloat(b.rating) - parseFloat(a.rating)
      );
      this.setState({ activities, loaded: true });
    });
  }

  componentDidMount() {
    this.getActivities();
  }

  render() {
    return (
      <div className={styles.wrapper}>
        <div className={styles.intro}>
          <h6 className={styles.featured}>Featured</h6>
          <h2 className={styles.title}>Explore London's Christmas Markets</h2>
          <p className={styles.text}>
            Whether you've been naughty or nice, get into the Christmas spirit
            with our top markets from around London
          </p>
        </div>
        <Activities
          limit={this.state.limit}
          activities={this.state.activities}
          loaded={this.state.loaded}
          history={this.props.history}
        />
        <ShowMore
          onClick={() => this.increaseLimit()}
          buttonText={"Show more"}
          limit={this.state.limit}
          listLength={this.state.activities.length}
        />
      </div>
    );
  }
}

export default Featured;
