import React, { Component } from "react";
import firebase from "../../firebase.js";

import Star from "./star.svg";
import backSymbol from "./backSymbol.svg";

import styles from "./activityDetails.module.sass";

class ActivityDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activityDetails: {},
      loaded: false,
      liked: false,
      imageUrl: null
    };
  }

  getActivityDetails() {
    const url = this.props.pathname;
    const activityId = url.slice(12, url.length);
    const query = firebase.database().ref(`activities/${activityId}`);
    query.on("value", snapshot => {
      const activityDetails = snapshot.val();
      this.setState({ activityDetails });
    });
  }

  getImage() {
    if (this.state.activityDetails.imageRef) {
      const storage = firebase.storage().ref();
      storage
        .child(`activityImages/${this.state.activityDetails.imageRef}`)
        .getDownloadURL()
        .then(url => {
          this.setState({ imageUrl: url, loaded: true });
        })
        .catch(function(error) {
          switch (error.code) {
            case "storage/object-not-found":
              // File doesn't exist
              break;
            case "storage/unauthorized":
              // User doesn't have permission to access the object
              break;
            case "storage/canceled":
              // User canceled the upload
              break;
            case "storage/unknown":
              // Unknown error occurred, inspect the server response
              break;
            default:
              break;
          }
        });
    }
  }

  toggleLike() {
    const userId = firebase.auth().currentUser.uid;
    const activityId = this.state.activityDetails.id;
    if (!this.state.liked) {
      firebase
        .database()
        .ref(`likedActivities/${userId}`)
        .child(activityId)
        .set(activityId);
      this.setState({
        liked: true
      });
    } else if (this.state.liked) {
      firebase
        .database()
        .ref(`likedActivities/${userId}`)
        .child(activityId)
        .remove();
      this.setState({
        liked: false
      });
    }
  }

  checkLikedActivity() {
    const url = this.props.pathname;
    const userId = firebase.auth().currentUser.uid;
    const activityId = url.slice(12, url.length);
    const query = firebase
      .database()
      .ref(`likedActivities/${userId}/${activityId}`);
    query.on("value", snapshot => {
      const likedActivityID = snapshot.val();
      if (likedActivityID) {
        this.setState({ liked: true });
      }
    });
  }

  componentDidMount() {
    window.scroll(0, 0);
    this.getActivityDetails();
    this.checkLikedActivity();
  }

  componentDidUpdate() {
    if (!this.state.loaded) {
      this.getImage();
    }
  }

  goBack = () => {
    this.props.history.goBack();
  };

  render() {
    return (
      <div className={styles.activityDetails}>
        <div className={styles.header}>
          <div className={styles.back} onClick={() => this.goBack()}>
            <img
              className={styles.backSymbol}
              src={backSymbol}
              alt="Back Symbol"
            />
            Back
          </div>
          <div>
            <div
              className={this.state.liked ? styles.liked : styles.like}
              onClick={() => this.toggleLike()}
            />
          </div>
        </div>
        <div
          style={{
            backgroundImage: `linear-gradient(0deg, rgba(0,0,0,0.00) 0%, rgba(0,0,0,0.3) 100%), url(${
              this.state.imageUrl
                ? this.state.imageUrl.slice(0, this.state.imageUrl.length)
                : null
            })`,
            backgroundPosition: "50% 50%"
          }}
          className={styles.image}
        ></div>
        <div className={styles.informationContainer}>
          <div>
            {this.state.activityDetails.location
              ? this.state.activityDetails.location.toUpperCase()
              : ""}
          </div>
          <h2 className={styles.title}>{this.state.activityDetails.title}</h2>
          <div className={styles.rating}>
            <img className={styles.star} src={Star} alt="Star" />
            {this.state.activityDetails.rating
              ? this.state.activityDetails.rating.toFixed(1)
              : null}
          </div>
          <div>
            <h4>Description</h4>
            {this.state.loaded
              ? this.state.activityDetails.description.map((paragraph, i) => {
                  return <p key={i}>{paragraph}</p>;
                })
              : null}
          </div>
        </div>
      </div>
    );
  }
}

export default ActivityDetail;
