import React, { Component } from "react";
import Header from "../components/header/header";
import LoginForm from "../components/loginForm/loginForm";

class Login extends Component {
  render() {
    return (
      <div>
        <Header
          authenticated={this.props.authenticated}
          noSearch
          selected={"Activities"}
        />
        <LoginForm
          history={this.props.history}
          authenticated={this.props.authenticated}
        />
      </div>
    );
  }
}

export default Login;
