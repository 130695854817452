import React, { Component } from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import firebase from "../../firebase";
import Button from "../../components/button/button";
import styles from "./signUpForm.module.sass";

class SignUpForm extends Component {
  handleSignUp = event => {
    event.preventDefault();
    const { username, email, password } = event.target.elements;
    // Check if username is unique
    const userQuery = firebase.database().ref(`usernames/${username.value}`);
    userQuery.once("value", snapshot => {
      if (!snapshot.exists()) {
        signUp({ username, email, password });
        // Create user
      } else {
        alert("This username is already taken. Try another");
      }
    });

    const signUp = async ({ username, email, password }) => {
      try {
        await firebase
          .auth()
          .createUserWithEmailAndPassword(email.value, password.value);
      } catch (error) {
        alert(error);
      }
      firebase.auth().onAuthStateChanged(user => {
        if (user) {
          const userId = firebase.auth().currentUser.uid;
          firebase
            .database()
            .ref("/usernames")
            .child(username.value)
            .set({
              userId: userId,
              email: email.value
            });
          firebase
            .database()
            .ref("/users")
            .child(userId)
            .set({
              username: username.value,
              email: email.value
            });
          this.props.history.push("/");
        } else {
          this.setState({
            authenticated: false
          });
        }
      });
    };
  };
  render() {
    return (
      <div className={styles.content}>
        <div>
          <h1>Create an Account</h1>
          <p className={styles.text}>
            Please be aware that we are currently we’re still in beta.
          </p>
          <form onSubmit={this.handleSignUp} className={styles.form}>
            <input
              name="username"
              type="username"
              placeholder="Username"
              aria-label="Username"
              className={styles.input}
              required
              pattern="[a-zA-Z0-9]+"
              autoCapitalize="none"
            />
            <input
              name="email"
              type="email"
              placeholder="Email"
              aria-label="Email"
              className={styles.input}
              required
            />
            <input
              name="password"
              type="password"
              placeholder="Password"
              aria-label="Password"
              className={styles.input}
              minLength={6}
              maxLength={100}
              required
            />
            <Button type="primary">Sign Up</Button>
          </form>
          <div>
            Already registered? <Link to={"/login"}>Log in here</Link>
          </div>
        </div>
        <div className={styles.imageContainer} />
      </div>
    );
  }
}

export default withRouter(SignUpForm);
