import React, { Component } from "react";
import Routes from "./routes";
import { BrowserRouter as Router } from "react-router-dom";

import "./App.sass";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Router>
        <Routes />
      </Router>
    );
  }
}

export default App;
