import React, { Component } from "react";
import firebase from "../../firebase.js";

import Star from "./star.svg";
import loadingLocation from "./loadingLocation.svg";
import loadingTitle from "./loadingTitle.svg";
import loadingRating from "./loadingRating.svg";

import styles from "./activity.module.sass";

class Activity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageUrl: null
    };
  }

  getImage() {
    if (this.props.imageRef) {
      const storage = firebase.storage().ref();
      storage
        .child(`activityImages/${this.props.imageRef}`)
        .getDownloadURL()
        .then(url => {
          this.setState({ imageUrl: url });
        })
        .catch(function(error) {
          switch (error.code) {
            case "storage/object-not-found":
              // File doesn't exist
              break;
            case "storage/unauthorized":
              // User doesn't have permission to access the object
              break;
            case "storage/canceled":
              // User canceled the upload
              break;
            case "storage/unknown":
              // Unknown error occurred, inspect the server response
              break;
            default:
              break;
          }
        });
    }
  }

  componentDidMount() {
    this.getImage();
  }

  openActivity() {
    this.props.history.push(`/activities/${this.props.id}`);
  }

  render() {
    if (this.props.loaded) {
      return (
        <div className={styles.activity} onClick={() => this.openActivity()}>
          <div
            style={{
              backgroundImage: `linear-gradient(0deg, rgba(0,0,0,0.00) 0%, rgba(0,0,0,0.3) 100%), url(${
                this.state.imageUrl
                  ? this.state.imageUrl.slice(0, this.state.imageUrl.length)
                  : null
              })`,
              backgroundPosition: "50% 50%"
              // backgroundImage:  url(${image})`
            }}
            className={styles.image}
          ></div>
          <div className={styles.informationContainer}>
            <div className={styles.location}>
              {this.props.location ? this.props.location.toUpperCase() : ""}
            </div>
            <div className={styles.title}>{this.props.title}</div>
            <div className={styles.rating}>
              <img className={styles.star} src={Star} alt="Star" />
              {this.props.rating ? this.props.rating.toFixed(1) : null}
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className={styles.activity}>
          <div className={styles.activityContent}>
            <div className={styles.imageLoading}></div>
            <div className={styles.informationContainer}>
              <div className={styles.location}>
                <img
                  className={styles.loadingTextImage}
                  src={loadingLocation}
                  alt="loading location"
                />
              </div>
              <div className={styles.title}>
                <img
                  className={styles.loadingTextImage}
                  src={loadingTitle}
                  alt="loading title"
                />
              </div>
              <div className={styles.rating}>
                <img
                  className={styles.loadingTextImage}
                  src={loadingRating}
                  alt="loading rating"
                />
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default Activity;
