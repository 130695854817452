import React from "react";

const Error = () => {
  return (
    <div>
      <div>404</div>
      <div>Error</div>
    </div>
  );
};

export default Error;
