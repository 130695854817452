import React from "react";

import styles from "./pageWrapper.module.sass";

const PageWrapper = ({ children }) => {
  return (
    <div className={styles.pageWrapper}>
      <div className={styles.pageWrapperContent}>{children}</div>
    </div>
  );
};

export default PageWrapper;
