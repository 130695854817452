import React, { Component } from "react";
import Header from "../components/header/header";
import Categories from "../components/categories/categories";
import TopActivities from "../components/topActivities/topActivities";
import Featured from "../components/featured/featured.js";

import AllCategory from "../images/All.png";
import ActiveCategory from "../images/Active.png";
import BarsCategory from "../images/Bars.png";
import BrunchCategory from "../images/Brunch.png";
import CoffeeCategory from "../images/Coffee.png";
import CultureCategory from "../images/Culture.png";
import OutdoorsCategory from "../images/Outdoors.png";
import DinnerCategory from "../images/Dinner.png";

class Discover extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: "All"
    };
  }

  handleChange = event => {
    this.setState({ selected: event.target.value });
  };

  render() {
    return (
      <div>
        <Header
          authenticated={this.props.authenticated}
          selected={"Activities"}
        />
        <h1>Discover</h1>
        <h3>Categories</h3>
        <Categories
          selected={this.state.selected}
          onChange={this.handleChange}
          categories={[
            {
              title: "All",
              image: AllCategory
            },
            {
              title: "Brunch",
              image: BrunchCategory
            },
            {
              title: "Bars",
              image: BarsCategory
            },
            {
              title: "Coffee",
              image: CoffeeCategory
            },
            {
              title: "Dinner",
              image: DinnerCategory
            },
            {
              title: "Active",
              image: ActiveCategory
            },
            {
              title: "Culture",
              image: CultureCategory
            },
            {
              title: "Outdoors",
              image: OutdoorsCategory
            }
          ]}
        />
        <TopActivities
          history={this.props.history}
          selected={this.state.selected}
        />
        <Featured history={this.props.history} />
      </div>
    );
  }
}

export default Discover;
