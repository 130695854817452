import React from "react";
import firebase from "../firebase";
import Header from "../components/header/header";

const Account = ({ authenticated }) => {
  return (
    <div>
      <Header authenticated={authenticated} noSearch selected={"Activities"} />
      <h1>Account</h1>
      <div className={"link"} onClick={() => firebase.auth().signOut()}>
        Sign Out
      </div>
    </div>
  );
};

export default Account;
