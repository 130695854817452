import React, { Component } from "react";
import firebase from "../../firebase.js";
import Activities from "../../components/activities/activities.js";
import ShowMore from "../../components/showMore/showMore.js";

class TopActivities extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activities: [],
      selected: null,
      loaded: false,
      limit: 6
    };
  }

  increaseLimit = () => {
    let newLimit = this.state.limit + 6;
    this.getActivities();
    this.setState({
      limit: newLimit
    });
  };

  getActivities() {
    if (this.state.loaded) {
      this.setState({
        loaded: false
      });
    }
    const getQuery = () => {
      if (this.props.selected === "All") {
        const query = firebase
          .database()
          .ref("activities")
          .orderByChild("rating")
          .limitToLast(this.state.limit * 2 + 1);
        return query;
      } else {
        const query = firebase
          .database()
          .ref("activities")
          .orderByChild("category")
          .equalTo(this.props.selected)
          .limitToLast(this.state.limit * 2 + 1);
        return query;
      }
    };
    getQuery().once("value", snapshot => {
      const response = [];
      snapshot.forEach(childSnapshot => {
        const item = childSnapshot.val();
        item.key = childSnapshot.key;
        response.push(item);
      });
      const activities = response.sort(
        (a, b) => parseFloat(b.rating) - parseFloat(a.rating)
      );
      if (this.props.selected !== this.state.selected) {
        this.setState({
          limit: 6
        });
      }
      this.setState({
        activities,
        loaded: true,
        selected: this.props.selected
      });
    });
  }

  componentDidMount() {
    this.getActivities();
  }

  componentDidUpdate() {
    if (this.props.selected !== this.state.selected) {
      this.getActivities();
    }
  }

  render() {
    return (
      <div>
        <h2>Top Picks</h2>
        <Activities
          limit={this.state.limit}
          activities={this.state.activities}
          loaded={this.state.loaded}
          history={this.props.history}
        />
        <ShowMore
          onClick={() => this.increaseLimit()}
          buttonText={"Show more"}
          limit={this.state.limit}
          listLength={this.state.activities.length}
        />
      </div>
    );
  }
}

export default TopActivities;
