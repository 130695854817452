import React, { Component } from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import firebase from "../../firebase";
import Button from "../../components/button/button";

import styles from "./loginForm.module.sass";

class Login extends Component {
  handleLogin = event => {
    event.preventDefault();
    const { username, password } = event.target.elements;
    // Find userId and check username
    const userQuery = firebase
      .database()
      .ref(`usernames/${username.value}/email`);
    userQuery.once("value", snapshot => {
      if (snapshot.exists()) {
        const email = snapshot.val();
        login({ email, password });
        // Create user
      } else {
        alert("This username does not exist. Try another");
      }
    });
    const login = async ({ email, password }) => {
      try {
        await firebase.auth().signInWithEmailAndPassword(email, password.value);
        this.props.history.push("/");
      } catch (error) {
        alert(error);
      }
    };
  };
  render() {
    return (
      <div className={styles.content}>
        <div>
          <h1>Login</h1>
          <p className={styles.text}>
            Enter your email and password below to login
          </p>
          <form onSubmit={this.handleLogin} className={styles.form}>
            <input
              name="username"
              type="text"
              placeholder="Username"
              aria-label="Username"
              className={styles.input}
              required
              pattern="[a-zA-Z0-9]+"
              autoCapitalize="none"
            />
            <input
              name="password"
              type="password"
              placeholder="Password"
              aria-label="Password"
              className={styles.input}
              minLength={6}
              maxLength={100}
              required
            />
            <Button type="primary">Login</Button>
          </form>

          <div>
            Not signed up yet?{" "}
            <Link to={"/sign-up"} className={styles.link}>
              Create an Account
            </Link>
          </div>
        </div>

        <div className={styles.imageContainer} />
      </div>
    );
  }
}

export default withRouter(Login);
