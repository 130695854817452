import React, { Component } from "react";

import ActivityDetail from "../components/activityDetail/activityDetail";

class Activity extends Component {
  render() {
    return (
      <div>
        <ActivityDetail
          history={this.props.history}
          pathname={this.props.pathname}
        />
      </div>
    );
  }
}

export default Activity;
