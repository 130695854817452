import React from "react";
import Button from "../button/button";

import styles from "./showMore.module.sass";

const ShowMore = ({ onClick, limit, listLength }) => {
  if (limit < listLength) {
    return (
      <div className={styles.wrapper}>
        <Button onClick={onClick} type={"secondary"}>
          Show more
        </Button>
      </div>
    );
  } else {
    return null;
  }
};
export default ShowMore;
