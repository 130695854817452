import React, { Component } from "react";
import firebase from "../../firebase.js";
import { Link } from "react-router-dom";

import styles from "./searchBar.module.sass";

class SearchBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activities: [],
      people: [],
      filteredItems: [],
      searchQuery: "",
      selected: this.props.selected
    };
  }
  getActivities() {
    const activitiesRef = firebase.database().ref("activities");
    const query = activitiesRef;
    query
      .once("value")
      .then(snapshot => {
        const response = [];
        snapshot.forEach(childSnapshot => {
          const activity = childSnapshot.val();
          activity.key = childSnapshot.key;
          response.push(activity);
        });
        const activities = response.reverse();
        this.setState({ activities });
      })
      .catch(error => {
        console.error(error);
      });
  }
  getPeople() {
    const peopleRef = firebase.database().ref("usernames");
    const query = peopleRef;
    query
      .once("value")
      .then(snapshot => {
        const response = [];
        snapshot.forEach(childSnapshot => {
          const person = childSnapshot.val();
          person.id = childSnapshot.key;
          response.push(person);
        });
        const people = response.reverse();
        this.setState({ people });
      })
      .catch(error => {
        console.error(error);
      });
  }

  handleSearchQuery = event => {
    this.setState({ searchQuery: event.target.value });
    this.filterList();
  };

  filterList = () => {
    if (this.state.selected === "Activities") {
      let filteredItems = this.state.activities;
      filteredItems = filteredItems.filter(activity => {
        return (
          activity.title
            .toString()
            .toLowerCase()
            .search(this.state.searchQuery.toLowerCase()) !== -1
        );
      });
      this.setState({ filteredItems: filteredItems });
    } else {
      let filteredItems = this.state.people;
      filteredItems = filteredItems.filter(person => {
        return (
          person.id
            .toString()
            .toLowerCase()
            .search(this.state.searchQuery.toLowerCase()) !== -1
        );
      });
      this.setState({ filteredItems: filteredItems });
    }
  };

  clearSearch = () => {
    document.getElementById("search").value = "";
    this.setState({ searchQuery: "" });
    this.setState({ filteredItems: [] });
    this.props.onClick();
  };

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClick, false);
    this.getActivities();
    this.getPeople();
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClick, false);
  }

  handleClick = e => {
    if (!this.node.contains(e.target)) {
      this.handleClickOutside();
    }
  };

  handleClickOutside() {
    this.clearSearch();
    this.props.makeSearchBarInactive();
  }

  handleSelectedChange = event => {
    this.nameInput.focus();
    this.setState({
      selected: event.target.value
    });
    this.setState(
      {
        selected: event.target.value
      },
      () => {
        this.filterList();
      }
    );
  };

  render() {
    return (
      <div
        ref={node => (this.node = node)}
        className={
          this.props.searchActive ? styles.searchActive : styles.search
        }
        onClick={!this.props.searchActive ? this.props.onClick : null}
      >
        <input
          type="text"
          id="search"
          name="search"
          aria-label="Search"
          placeholder="Search"
          autoComplete="off"
          onChange={this.handleSearchQuery}
          onClick={this.filterList}
          ref={input => {
            this.nameInput = input;
          }}
        />
        <div
          className={
            this.props.searchActive ? styles.cancel : styles.cancelHidden
          }
          onClick={this.props.searchActive ? this.clearSearch : null}
        >
          Cancel
        </div>
        <div className={styles.suggestionsContainer}>
          <div
            className={
              this.props.searchActive
                ? styles.suggestions
                : styles.hiddenSuggestions
            }
          >
            <div className={styles.suggestionsContent}>
              <div className={styles.selectWrapper}>
                <label
                  className={
                    this.state.selected === "Activities"
                      ? styles.checkedButton
                      : styles.selectButton
                  }
                >
                  <input
                    className={styles.radio}
                    type="radio"
                    value="Activities"
                    checked={this.state.selected === this.value}
                    onChange={this.handleSelectedChange}
                  />
                  Activities
                </label>
                <label
                  className={
                    this.state.selected === "People"
                      ? styles.checkedButton
                      : styles.selectButton
                  }
                >
                  <input
                    className={styles.radio}
                    type="radio"
                    value="People"
                    checked={this.state.selected === this.value}
                    onChange={this.handleSelectedChange}
                  />
                  People
                </label>
              </div>
              <h4
                className={styles.suggestionsTitle}
                onClick={() => this.handleClickOutside()}
              >
                Suggestions
              </h4>

              <div
                className={styles.suggestionsItems}
                onClick={() => this.handleClickOutside()}
              >
                {this.state.selected === "Activities"
                  ? this.state.filteredItems.map(function(item) {
                      return item ? (
                        <Link
                          key={item.id}
                          className={styles.itemLink}
                          to={`/activities/${item.id}`}
                        >
                          <div className={styles.item} key={item.id}>
                            {item.title}
                          </div>
                        </Link>
                      ) : null;
                    })
                  : this.state.filteredItems.map(function(item) {
                      return item ? (
                        <Link
                          key={item.id}
                          className={styles.itemLink}
                          to={`/profiles/${item.id}`}
                        >
                          <div className={styles.item} key={item.id}>
                            {item.id}
                          </div>
                        </Link>
                      ) : null;
                    })}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SearchBar;
