import React, { Component } from "react";
import Header from "../components/header/header";
import SignUpForm from "../components/signUpForm/signUpForm";

class SignUp extends Component {
  render() {
    return (
      <div>
        {" "}
        <Header
          authenticated={this.props.authenticated}
          noSearch
          selected={"Activities"}
        />
        <SignUpForm
          history={this.props.history}
          authenticated={this.props.authenticated}
        />
      </div>
    );
  }
}

export default SignUp;
