import React, { Component } from "react";
import firebase from "../../firebase";
import { Link } from "react-router-dom";

import styles from "./header.module.sass";

import Logo from "./logo.svg";
import Button from "../button/button";
import SearchBar from "../searchBar/searchBar";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      burgerOpen: false,
      searchActive: false,
      username: null
    };
  }

  closeBurgerMenu() {
    this.setState({ burgerOpen: false });
  }

  toggleSearchBar() {
    this.setState({ searchActive: !this.state.searchActive });
  }

  makeSearchBarInactive = () => {
    this.setState({ searchActive: false });
  };

  toggleBurgerMenu(burger) {
    this.setState({ burgerOpen: !this.state.burgerOpen });
  }

  getUsername = () => {
    if (this.props.authenticated) {
      const userId = firebase.auth().currentUser.uid;
      const userQuery = firebase.database().ref(`users/${userId}`);
      userQuery.once("value", snapshot => {
        const userDetails = snapshot.val();
        const username = userDetails.username;
        this.setState({
          username
        });
      });
    }
  };

  componentDidMount() {
    document.addEventListener("burger toggle", this.toggleBurgerMenu);
    this.getUsername();
  }

  componentWillUnmount() {
    document.removeEventListener("burger toggle", this.toggleBurgerMenu);
  }

  render() {
    return (
      <nav className={styles.nav}>
        <div className={styles.navContent}>
          <div
            className={
              this.props.noSearch
                ? styles.navLeftPermanent
                : this.state.searchActive
                ? styles.navLeftHidden
                : styles.navLeft
            }
          >
            <Link to="/" onClick={burger => this.closeBurgerMenu(burger)}>
              <img src={Logo} alt="website logo" />
            </Link>
          </div>
          <div className={styles.navRight}>
            {!this.props.noSearch ? (
              <SearchBar
                searchActive={this.state.searchActive}
                onClick={searchButton => this.toggleSearchBar(searchButton)}
                makeSearchBarInactive={this.makeSearchBarInactive}
                selected={this.props.selected}
              />
            ) : null}
            <div
              className={
                this.state.searchActive
                  ? styles.burgerContainerHidden
                  : this.state.burgerOpen
                  ? styles.burgerContainerOpen
                  : styles.burgerContainer
              }
              onClick={burger => this.toggleBurgerMenu(burger)}
            >
              <div className={styles.allBars}>
                <div className={styles.bar1} />
                <div className={styles.bar2} />
                <div className={styles.bar3} />
              </div>
            </div>
            {this.props.authenticated ? (
              <div
                className={this.state.burgerOpen ? styles.show : styles.menu}
              >
                <div className={styles.navItem}>
                  <Link to="/" onClick={burger => this.closeBurgerMenu(burger)}>
                    Discover
                  </Link>
                </div>
                <div className={styles.navItem}>
                  <Link
                    to={"/profiles/" + this.state.username}
                    onClick={burger => this.closeBurgerMenu(burger)}
                  >
                    Profile
                  </Link>
                </div>
                <div className={styles.navItem}>
                  <Link
                    to="/account"
                    onClick={burger => this.closeBurgerMenu(burger)}
                  >
                    Account
                  </Link>
                </div>
              </div>
            ) : (
              <div
                className={this.state.burgerOpen ? styles.show : styles.menu}
              >
                <div className={styles.navItem}>
                  <Link
                    to="/login"
                    onClick={burger => this.closeBurgerMenu(burger)}
                  >
                    Login
                  </Link>
                </div>
                <div className={styles.navItem}>
                  <Button
                    type="primary"
                    to="/sign-up"
                    onClick={burger => this.closeBurgerMenu(burger)}
                  >
                    Sign up
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </nav>
    );
  }
}

export default Header;
