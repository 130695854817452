import React from "react";
import Activity from "../activity/activity";

import styles from "./activities.module.sass";

const Activities = ({ activities, limit, loaded, history }) => {
  if (loaded) {
    return (
      <div className={styles.container}>
        {activities
          ? activities.slice(0, limit).map(activity => {
              return (
                <Activity
                  key={activity.id}
                  id={activity.id}
                  imageRef={activity.imageRef}
                  location={activity.location}
                  title={activity.title}
                  rating={activity.rating}
                  loaded={loaded}
                  history={history}
                />
              );
            })
          : null}
      </div>
    );
  } else {
    return (
      <div className={styles.container}>
        <Activity loaded={loaded} />
        <Activity loaded={loaded} />
        <Activity loaded={loaded} />
        <Activity loaded={loaded} />
        <Activity loaded={loaded} />
        <Activity loaded={loaded} />
        <Activity loaded={loaded} />
        <Activity loaded={loaded} />
      </div>
    );
  }
};

export default Activities;
