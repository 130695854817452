import React, { Component } from "react";
import firebase from "../../firebase.js";
import Activities from "../activities/activities";
import ShowMore from "../showMore/showMore";

import styles from "./list.module.sass";

class List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activities: [],
      activitiesLoaded: false,
      loaded: false,
      limit: 6,
      userId: null
    };
  }

  increaseLimit = () => {
    let newLimit = this.state.limit + 6;
    this.setState({
      limit: newLimit
    });
  };

  getLikedActivityIds = () => {
    this.setState({
      profileUserId: this.props.profileUserId,
      activitiesLoaded: false,
      loaded: false
    });
    const idQuery = firebase
      .database()
      .ref("likedActivities")
      .child(this.props.profileUserId);
    let activities = [];
    idQuery.once("value", snapshot => {
      snapshot.forEach(childSnapshot => {
        const itemId = childSnapshot.val();
        const query = firebase.database().ref(`activities/${itemId}`);
        query.once("value", snapshot => {
          const activity = snapshot.val();
          activities.push(activity);
          this.setState({
            activities: activities
          });
        });
      });
    });
    this.setState({
      activitiesLoaded: true
    });
  };

  // componentDidMount() {
  // this.getLikedActivityIds();
  // }

  componentDidUpdate() {
    if (this.props.profileUserId && !this.state.activitiesLoaded) {
      this.getLikedActivityIds();
    }
    if (!this.state.loaded && this.state.activitiesLoaded) {
      this.setState({
        loaded: true
      });
    }
    if (this.props.profileUserId !== this.state.profileUserId) {
      this.getLikedActivityIds();
    }
  }

  render() {
    return (
      <div>
        {this.state.activities ? (
          <div className={styles.wrapper}>
            <h2>List</h2>
            <Activities
              limit={this.state.limit}
              activities={this.state.activities}
              loaded={this.state.loaded}
              history={this.props.history}
            />
            <ShowMore
              onClick={() => this.increaseLimit()}
              buttonText={"Show more"}
              limit={this.state.limit}
              listLength={this.state.activities.length}
            />
          </div>
        ) : (
          "foo"
        )}
      </div>
    );
  }
}

export default List;
