import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import firebase from "./firebase";
import PageWrapper from "./components/pageWrapper/pageWrapper";

import Discover from "./views/discoverView";
import SignUp from "./views/signupView";
import Login from "./views/loginView";
import Account from "./views/accountView";
import ProfileView from "./views/profileView";
import PrivateRoute from "./authentication/privateRoute";
import Error from "./views/errorView.js";
import ActivityView from "./views/activityView";

class Routes extends Component {
  state = {
    loading: true,
    authenticated: false,
    currentUser: null,
    username: null
  };

  componentDidMount() {
    firebase.auth().onAuthStateChanged(currentUser => {
      if (currentUser) {
        this.setState({
          authenticated: true,
          currentUser: currentUser,
          loading: false
        });
      } else {
        this.setState({
          authenticated: false,
          currentUser: null,
          loading: false
        });
      }
    });
  }

  render() {
    const { authenticated, loading } = this.state;
    if (loading) {
      return null;
    }
    return (
      <PageWrapper>
        <Switch>
          <Route
            exact
            path="/sign-up"
            authenticated={authenticated}
            component={() => <SignUp />}
          />
          <Route
            exact
            path="/login"
            authenticated={authenticated}
            component={() => <Login />}
          />
          <PrivateRoute
            exact
            path="/"
            authenticated={authenticated}
            component={props => (
              <Discover
                history={props.history}
                pathname={props.location.pathname}
                authenticated={props.authenticated}
              />
            )}
          />
          <PrivateRoute
            exact
            path="/account"
            authenticated={authenticated}
            component={props => <Account authenticated={props.authenticated} />}
          />
          <PrivateRoute
            exact
            path="/activities/:activityId"
            authenticated={authenticated}
            component={props => (
              <ActivityView
                history={props.history}
                pathname={props.location.pathname}
              />
            )}
          />
          <PrivateRoute
            exact
            path="/profiles/:profileId"
            authenticated={authenticated}
            component={props => (
              <ProfileView
                history={props.history}
                pathname={props.location.pathname}
                authenticated={props.authenticated}
              />
            )}
          />

          <Route path="*" component={Error} />
        </Switch>
      </PageWrapper>
    );
  }
}

export default Routes;
