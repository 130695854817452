import React from "react";
import { Link } from "react-router-dom";
import styles from "./button.module.sass";

const Button = ({ to, onClick, children, type }) => {
  return to ? (
    <Link to={to} onClick={onClick}>
      <button
        className={type === "primary" ? styles.button : styles.buttonSecondary}
      >
        {children}
      </button>
    </Link>
  ) : (
    <button
      onClick={onClick}
      className={type === "primary" ? styles.button : styles.buttonSecondary}
    >
      {children}
    </button>
  );
};

export default Button;
